import React, { memo } from 'react'
import { BWPageContent } from 'src/libs/qb-brand-web-components'
import { faGift } from '@fortawesome/pro-light-svg-icons'

import PageHeaderWrapper from 'wrappers/PageHeaderWrapper'
import HomeLinkButton from '../shared/button/HomeLinkButton'
import OpenRewardBox from './OpenRewardBox'
import Withdraw from './Withdraw'
import WithdrawMigration from './WithdrawMigration'
import MilestoneBonus from './MilestoneBonus'
import FilterMenu from '../shared/filterMenu/FilterMenu'
import CommunityJackpot from './CommunityJackpot'

const RewardBoxes = ({
  t,
  tokenProfile: { symbol },
  openRewardProps,
  withdrawProps,
  withdrawPropsOld,
  rewardBoxErr,
  rewardBoxStatus,
  milestoneProfile,
  onScrollToWithdraw,
  onShowPopup
}) => {
  return (
    <>
      <HomeLinkButton t={t} />
      <PageHeaderWrapper
        label={t('reward-box.reward-boxes')}
        description={t('reward-box.reward-boxes-desc')}
        icon={faGift}
        header
      />
      <BWPageContent>
        {withdrawPropsOld.earnedReward > 0 ? (
          <WithdrawMigration
            t={t}
            rewardBoxErr={rewardBoxErr}
            rewardBoxStatus={rewardBoxStatus}
            {...withdrawPropsOld}
          />
        ) : (
          <>
            <OpenRewardBox
              t={t}
              symbol={symbol}
              rewardBoxErr={rewardBoxErr}
              rewardBoxStatus={rewardBoxStatus}
              milestoneProfile={milestoneProfile}
              {...openRewardProps}
            />
            <FilterMenu
              options={[
                { label: t('reward-box.daily-milestones') },
                { label: t('reward-box.withdraw-rewards') }
              ]}
              onChange={onScrollToWithdraw}
            />
            <CommunityJackpot
              t={t}
              milestoneProfile={milestoneProfile}
              symbol={symbol}
              onShowPopup={onShowPopup}
            />
            <MilestoneBonus
              t={t}
              milestoneProfile={milestoneProfile}
              symbol={symbol}
              onShowPopup={onShowPopup}
            />
            <Withdraw
              t={t}
              rewardBoxErr={rewardBoxErr}
              rewardBoxStatus={rewardBoxStatus}
              {...withdrawProps}
            />
          </>
        )}
      </BWPageContent>
    </>
  )
}

export default memo(RewardBoxes)
