import styled from '@emotion/styled'
import React, { memo } from 'react'
import {
  BWText,
  CSS_FLEX_ITEMS_CENTER,
  CSS_FULL_WIDTH,
  cssCustomGap
} from 'src/libs/qb-brand-web-components'

const FilterMenu = ({ value, options, onChange }) => {
  return (
    <MenuContainer>
      {options.map((option, index) => (
        <BWText
          key={index}
          label={option.label}
          size={18}
          weight={700}
          alignCenter
          onClick={() => onChange(option)}
          css={{
            padding: 8,
            textDecoration: value === option.value && 'underline',
            cursor: 'pointer'
          }}
        />
      ))}
    </MenuContainer>
  )
}

export default memo(FilterMenu)

const MenuContainer = styled.div`
  ${CSS_FLEX_ITEMS_CENTER};
  ${CSS_FULL_WIDTH};
  ${cssCustomGap(10)};
`
