import React, { useEffect } from 'react'
import { openExternalUrl } from 'src/libs/qb-brand-web-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useParams } from 'react-router-dom'

import Loading from '../components/shared/loading/Loading'
import { DYNAMIC_PAGE_REDIRECT_PATHS } from '../constants/externalUrls'
import { getSignedUrl } from '../store/actions/redeem'
import { COE_SSO_SESSION_PATH } from '../constants/api'
import { buildSignedUrl } from '../util/navigation.helpers'
import { questFulfillUrl } from '../util/quest.helpers'

const PageRedirectContainer = ({ getSignedUrl, signedUrl, authProfile }) => {
  const params = useParams()

  useEffect(() => {
    if (signedUrl) {
      openExternalUrl(signedUrl, true)
    }
  }, [signedUrl])

  useEffect(() => {
    const path = params?.path
    if (
      [
        DYNAMIC_PAGE_REDIRECT_PATHS.TRUTHBRUSH,
        DYNAMIC_PAGE_REDIRECT_PATHS.Z_DENTAL
      ].includes(path)
    ) {
      getSignedUrl(buildSignedUrl(path))
    } else if (
      [
        DYNAMIC_PAGE_REDIRECT_PATHS.REDEEM,
        DYNAMIC_PAGE_REDIRECT_PATHS.VIEW_SHOP
      ].includes(path)
    ) {
      const brandId = params?.brand
      getSignedUrl(COE_SSO_SESSION_PATH + (brandId ? `/demo/${brandId}` : ''))
    }
  }, [params])

  useEffect(() => {
    const path = params?.path
    const id = params?.brand
    const authToken =
      authProfile?.cognitoUser?.signInUserSession?.accessToken?.jwtToken
    if (path === DYNAMIC_PAGE_REDIRECT_PATHS.QUEST && id && authToken) {
      openExternalUrl(questFulfillUrl(id, authToken), true)
    }
  }, [params, authProfile])

  return <Loading />
}

const mapStateToProps = ({ redeemReducer, authReducer }) => {
  const { redeemProfile } = redeemReducer
  const { authProfile } = authReducer
  return {
    signedUrl: redeemProfile.signedUrl,
    authProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSignedUrl
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageRedirectContainer)
