import React, { memo } from 'react'
import { Link } from 'react-router-dom'

import { HOME_URL } from '../../../constants/navigation'
import BackButton from './BackButton'

const HomeLinkButton = ({ t }) => {
  return (
    <BackButton
      t={t}
      to={HOME_URL}
      as={Link}
      styles={{ color: 'inherit', textDecoration: 'inherit' }}
    />
  )
}

export default memo(HomeLinkButton)
