import React, { forwardRef, memo } from 'react'
import { Trans } from 'react-i18next'

import CardWithBody from '../shared/panel/CardWithBody'
import Button from '../shared/button/Button'
import {
  REWARD_BOX_PROCESS_STATUS,
  REWARD_BOX_WITHDRAW_MAX_LIMIT
} from '../../constants/rewardBox'
import ProgressBar from '../shared/progressBar/ProgressBar'
import {
  BW_COLOR_ERROR,
  BWPanelContent,
  BWText,
  formatCurrencyValue
} from 'src/libs/qb-brand-web-components'
import { percentageOfCompletion } from '../../util/number.helpers'

const Withdraw = forwardRef(
  (
    {
      t,
      onWithdraw,
      rewardBoxStatus,
      rewardBoxErr,
      rewardBoxMsg,
      earnedReward
    },
    ref
  ) => {
    const refundErr = rewardBoxErr.refund
    const isRefunding =
      rewardBoxStatus === REWARD_BOX_PROCESS_STATUS.REFUNDING && !refundErr
    return (
      <>
        <CardWithBody
          title={
            <>
              <div ref={ref} />
              {t('reward-box.withdraw-your-base-rewards')}
            </>
          }
          desc={t('reward-box.withdraw-your-rewards-desc', {
            value: REWARD_BOX_WITHDRAW_MAX_LIMIT
          })}
          id='withdraw-base-rewards'
          body={
            <>
              <ProgressBar
                leftLabel='$0'
                rightLabel={`$${REWARD_BOX_WITHDRAW_MAX_LIMIT}`}
                completed={percentageOfCompletion(
                  earnedReward,
                  REWARD_BOX_WITHDRAW_MAX_LIMIT
                )}
                info={
                  <BWText
                    label={
                      <Trans
                        i18nKey='reward-box.current-pool-value'
                        values={{
                          value: formatCurrencyValue(earnedReward, 3) || 0
                        }}
                      />
                    }
                  />
                }
              />
              <BWPanelContent>
                <Button
                  label={t('reward-box.withdraw-your-rewards')}
                  onClick={onWithdraw}
                  disabled={
                    isRefunding || earnedReward < REWARD_BOX_WITHDRAW_MAX_LIMIT
                  }
                />
                {refundErr && (
                  <BWText
                    color={BW_COLOR_ERROR}
                    label={t(refundErr)}
                    size={14}
                    weight={500}
                    alignCenter
                  />
                )}
                {rewardBoxMsg.refund && (
                  <BWText
                    label={rewardBoxMsg.refund}
                    size={14}
                    weight={500}
                    alignCenter
                  />
                )}
              </BWPanelContent>
            </>
          }
        />
      </>
    )
  }
)

Withdraw.displayName = 'Withdraw'

export default memo(Withdraw)
