export const CLAIM_REWARD_BOX_LIMIT_PER_DAY = 1000
export const REWARD_BOX_WITHDRAW_MAX_LIMIT = 10

export const REWARD_BOX_PROCESSING_TIME = 180 // seconds

export const REWARD_BOX_PROCESS_STATUS = {
  INIT: 0,
  DEPOSITING: 1,
  DEPOSITED: 2,
  REFUNDING: 3
}

export const DEFAULT_ETH_PRICE = 2420

export const WALLET_ACCOUNT_STATUS = {
  CONNECTED: 'connected'
}

export const JACKPOT_WINNER_REFRESH_TIME = 1800 // seconds / 30 minutes

export const REWARD_BOX_MILESTONE_ID = {
  MILESTONE_1: 'milestone_1',
  HARVEST_BOOSTER: 'milestone_harvest_booster',
  MILESTONE_2: 'milestone_2'
}

export const REWARD_BOX_MILESTONE_JSON_URL = {
  HARVEST_BOOSTER: 'https://json.qiibeefoundation.org/harvestbooster.json',
  JACKPOT_WINNER: 'https://json.qiibeefoundation.org/jackpots.json'
}
