import React, { memo } from 'react'
import {
  BWPanel,
  BWText,
  BWIcon,
  FrontCenterContent,
  CSS_FULL_WIDTH
} from 'src/libs/qb-brand-web-components'
import { useTheme } from '@emotion/react'

import customConfig from 'customConfig'
import coeStorefrontLogo from '../../assets/coe-storefront.jpeg'
import { CircleBgContainer } from '../shared/common'
import { faIconCss, moonIconCss, COE_BANNER_STYLES } from '../../styles/shared'
import { openBlankRedirectPage } from '../../util/app/app.helpers'
import { coeRedemptionUrl } from '../../util/navigation.helpers'

const StoreFrontRedeem = ({ t, customCss }) => {
  const theme = useTheme()
  const isDisabledCoeIcon = customConfig.disableCoeIcon
  return (
    <BWPanel
      bgImage={coeStorefrontLogo}
      onClick={() => openBlankRedirectPage(coeRedemptionUrl())}
      gap={10}
      // css={[CSS_CUSTOM_PANEL_PADDING_30, CSS_GRID_COLUMN_THREE, CSS_FULL_WIDTH]}
      css={[COE_BANNER_STYLES, CSS_FULL_WIDTH, customCss]}
      anchor
    >
      <FrontCenterContent>
        {!isDisabledCoeIcon && (
          <CircleBgContainer isBgColor>
            <BWIcon
              icon={customConfig.coeCardIcon}
              moonIconCss={moonIconCss(theme)}
              faIconCss={faIconCss(theme)}
            />
          </CircleBgContainer>
        )}
        <BWText
          alignCenter
          label={t(customConfig.exploreMoreRewardsLabel)}
          weight={700}
        />
      </FrontCenterContent>
    </BWPanel>
  )
}

export default memo(StoreFrontRedeem)
